<template>
  <div>
    <b-button
      class="mb-2"
      @click="$router.push(`/usuario_admin/${model.unity_id}`)"
      >voltar</b-button
    >
    <Card class="pb-2" :title="'Novo Usuário Administrador'">
      <b-row>
        <b-col>
          <label>Nome</label>
          <b-input v-model="model.name"></b-input>
        </b-col>
        <b-col>
          <label>E-mail</label>
          <b-input v-model="model.email"></b-input>
        </b-col>
        <div class="d-flex align-items-end">
          <b-button v-if="!editing" @click="save_user_admin">Salvar</b-button>
          <b-button v-else @click="update_user_admin">Alterar</b-button>
        </div>
      </b-row>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      editing:false,
      model: {
        name: "",
        email: "",
        unity_id: "",
        type: "1",
        active: "1",
      },
    };
  },
  methods: {
    save_user_admin() {
      const response = this.$store.dispatch(
        "unities/save_user_admin",
        this.model
      );

      if (response) {
        this.$router.push(`/usuario_admin/${this.model.unity_id}`);
      }
    },
    async update_user_admin(){
      const response = await this.$store.dispatch('http_put', {
        url:'user',
        data: this.model
       
      })
      if(response.status == 200){
        this.$router.push(`/usuario_admin/${this.model.unity_id}`)
      }      
    }
  },
  async mounted() {
    const unity_id = this.$route.params.unityId;
    this.model.unity_id = unity_id;
    const id = this.$route.params.id;
    if (id) {
      this.editing = true
      const response = await this.$store.dispatch("http_get", {
        url: `/user-unity/${unity_id}`,
      });
      const index = _.findIndex(response.data.payload, (item) => {
        return item.id == id;
      });
      if (index >= 0) {
        this.model = response.data.payload[index];
      }
    }
  },
};
</script>

<style>
</style>